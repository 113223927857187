document.addEventListener('scroll', function() {
    var seccion = document.querySelector(".plusvalink2")
    var videoSection = document.getElementById('plusvalink');
    var rect = videoSection.getBoundingClientRect();
    if (rect.top < window.innerHeight && rect.bottom > 0) {
        setTimeout(() => {
            seccion.style.opacity = "1" // Restablecer el texto del botón
        }, 3000);
    } else {
        seccion.style.opacity = "0"
    }

    var seccion2 = document.querySelector(".cesco2")
    var videoSection2 = document.getElementById('cesco');
    var rect = videoSection2.getBoundingClientRect();
    if (rect.top < window.innerHeight && rect.bottom > 0) {
        setTimeout(() => {
            seccion2.style.opacity = "1" // Restablecer el texto del botón
        }, 3000);
    } else {
        seccion2.style.opacity = "0"
    }
});



var map = L.map('map').setView([20.709539445267534, -103.37831420507241], 15);

L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
}).addTo(map);

L.marker([20.709539445267534, -103.37831420507241]).addTo(map)

const navegador = document.querySelector(".navegador")
const botonNav = document.querySelector(".navegador-boton")
var value = 0
botonNav.addEventListener("click", function(){
    if(value == 0){
        navegador.style.transform = "translateY(0px)"
        value = value + 1 
    }else{
        navegador.style.transform = "translateY(-300px)"
        value = 0
    }
});
